.page {
    height: 100%;
}

.container {
    margin: 0px 4px;
    border: 1px solid var(--colour-page-border);
    border-radius: var(--border-radius-big);
    overflow: hidden;
    background-color: var(--colour-page-background);
}