.tab {
  font: var(--text-large);
  margin: 0 var(--size-padding-small);
  padding: var(--size-padding-small) var(--size-padding-big);
  border-radius: var(--border-radius-small);
  border: var(--border-width-small) solid transparent;

}

.unselected {
  --link-colour: transparent;
  --link-colour-hover: var(--button-hover-colour);
  --link-colour-active: var(--button-active-colour);
}

.unselected:link, .unselected:visited {
  text-decoration: none;
  padding: var(--size-padding-small) var(--size-padding-big);
}

.selected {
  background-color: var(--colour-settings-background);
}