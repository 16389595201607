.divider {
    display: flex;
    flex-direction: row;
}

.divider>*:nth-child(1) {
    flex: 0 0 25%;
}

.divider>*:nth-child(2) {
    flex: 0 0 50%;
}

.divider>*:nth-child(3) {
    flex: 0 0 25%;
}