.footer {
    text-align: center;
    font: var(--text-small);
    margin: var(--size-padding-small) 0px;

    --link-colour: transparent;
    --link-colour-hover: var(--colour-menu-button-hover);
    --link-colour-active: var(--colour-menu-button-active);
}

.divider {
    display: inline-block;
    margin: 0px var(--size-padding-big);
    color: var(--colour-disabled);
}

.footer a {
    display: inline-block;
    padding: var(--size-padding-small);
}