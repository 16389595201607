.container {
    background-color: var(--colour-settings-background);
    font: var(--text-settings);
    padding: 4px;
    --button-active-colour: var(--colour-page-border);
    --button-hover-colour: var(--colour-settings-hover);
    display: inline-flex;
    align-items: center;
}

.container button {
    display: inline-flex;
    align-items: center;
    margin-right: var(--size-padding-big);
    padding: var(--size-padding-small);
}

.icon {
    margin: 0.1rem var(--size-padding-small) 0 var(--size-padding-small);
    color: var(--colour-iconbutton);
}

.hidden {
    display: none;
}

.detail {
    color: var(--colour-text-secondary);
}