.byte {
    position: relative;
    padding-left: 3px;
    padding-right: 3px;
    outline: solid 1px #00000000;
    border-radius: 0.35em;
    outline-offset: -1px;
}

.invalid {
    color: #00000040;
}

.selected {
    color: #303030;
    background-color: #ff000040;
}

.selectedinvalid {
    color: #30303080;
    background-color: #ff000040;
}

.accessed {
    outline: solid 1px #78c3a8;
}

.divider {
    position: absolute;
    top: 0px;
    width: 6px;
    height: 18px;
    background-color: #00000080;
    padding: 0;
    border: none;
    margin: 1px;
    overflow: hidden;
    z-index: 100;
}

.before {
    left: -5px;
}

.after {
    right: -5px;
}

.break::after {
    content: "¶";
    color: #10401880;
    font-size: 0.75em;
    position: absolute;
}
