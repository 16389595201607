@media screen and (width <900px) {
    .outer {
        display: none !important;
    }
}

.outer {
    background-color: var(--colour-settings-background);
    width: 75px;
}

.map {
    border: var(--border-width-small) solid var(--colour-page-border);
}

.legend {
    margin: var(--size-padding-big);
    font: var(--text-small);
}

.label {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}

.swatch {
    display: inline-block;
    height: 1em;
    width: 1em;
    border: var(--border-width-small) solid var(--colour-page-border);
    vertical-align: middle;
    margin-left: var(--size-padding-small);
}