.panel {
    background-color: var(--colour-settings-background);
    font: var(--text-settings);
    padding: 4px;
    --button-active-colour: var(--colour-page-border);
    --button-hover-colour: var(--colour-settings-hover);
}

.iconButton {
    color: var(--colour-iconbutton);
}