.modal {
    --shadow-offset: var(--size-padding-small);

    position: absolute;
    padding: 0px;
    font: var(--text-medium);
    background-color: var(--colour-page-background);
    border: var(--border-width-small) solid var(--colour-page-border);
    border-radius: var(--border-radius-big);
    overflow-y: auto;
    overflow-x: hidden;
    z-index: 100;
    box-shadow: var(--shadow-offset) var(--shadow-offset) calc(2 * var(--shadow-offset)) var(--colour-modal-shadow);
    width: max-content;
}

.background {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--colour-modal-overlay);
    z-index: 10;
}