.wrapper {
    overflow: auto;
    will-change: transform;
    -webkit-overflow-scrolling: touch;
    height: 100%;
}

.inner {
    position:relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.item {
    position: absolute;
    left: 0;
}