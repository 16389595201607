/* FilePicker */
.filepickerContainer {
    overflow: hidden;
    height: 100%;
}

.projectsContainer {
    overflow: auto;
    height: 100%;
    min-width: 300px;
}

.overlay {
    width: 99%;
    height: 99%;
    position: absolute;
    z-index: 100;
    background-color: var(--colour-fetching-overlay);
}
