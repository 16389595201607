.graphic {
    width: var(--graphic-width);
    height: var(--graphic-height);
    display: inline-block;
    vertical-align: top;
}

.fitToSize {
    width: 100%;
    height: 100%;
}

.wrapper {
    margin: var(--size-padding-big);
    font: var(--text-tiny);
    display: inline-flex;
    flex-direction: column;
    vertical-align: top;
    --shadow-x-offset: var(--size-padding-small);
    --shadow-y-offset: var(--size-padding-small);
    --shadow-blur-radius: calc(2 * var(--size-padding-small));
    --shadow-colour: var(--colour-shadow-far);
    box-shadow: var(--shadow-x-offset) var(--shadow-y-offset) var(--shadow-blur-radius) var(--shadow-colour);
    border-radius: var(--border-radius-small);
}

.wrapper:hover {
    --shadow-colour: var(--colour-shadow-medium);
    --shadow-x-offset: calc(var(--size-padding-small) - var(--border-width-small));
    --shadow-y-offset: calc(var(--size-padding-small) - var(--border-width-small));
    margin: calc(var(--size-padding-big) - var(--border-width-small));
    border: var(--border-width-small) solid white;
}

.selected, .wrapper:active {
    --shadow-colour: var(--colour-shadow-near) !important;
    --shadow-x-offset: calc(var(--size-padding-small) - var(--border-width-medium)) !important;
    --shadow-y-offset: calc(var(--size-padding-small) - var(--border-width-medium)) !important;
    margin: calc(var(--size-padding-big) - var(--border-width-medium)) !important;
    border: var(--border-width-medium) solid var(--colour-page-background) !important;
}
