.listContainer {
    padding: var(--size-padding-small);
    position: relative;
}

.listContainer h4 {
    margin: 0;
    font: var(--text-large);
}

.listItemsContainer>div:first-of-type {
    border-top: none;
}

.listItemsContainer>div {
    border-top: var(--border-width-small) solid var(--colour-settings-background);
}

.listItemsContainer {
    margin-left: var(--size-padding-massive);
}

.pageControls {
    --button-active-colour: var(--colour-page-border);
    --button-hover-colour: var(--colour-settings-hover);
    display: inline-flex;
    align-items: center;
}

.pageControls>button {
    padding: var(--size-padding-small);
    margin: var(--size-padding-tiny);
}

.icon {
    margin: 0.1rem var(--size-padding-small) 0 var(--size-padding-small);
    color: var(--colour-iconbutton);
}

.detail {
    color: var(--colour-text-secondary);
}

.overlay {
    width: 99%;
    height: 99%;
    position: absolute;
    z-index: 100;
    background-color: var(--colour-fetching-overlay);
}