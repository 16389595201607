.panel {
    --button-hover-colour: var(--colour-menu-button-hover);
    --button-active-colour: var(--colour-menu-button-active);
}

.tab {
    font: var(--text-large);
    margin-left: var(--size-padding-big);
    padding: var(--size-padding-big);
}

.tab span {
    padding-left: var(--size-padding-small);
}

.icon {
    font: var(--text-small);
    margin: 0.2rem var(--size-padding-small) 0 var(--size-padding-small);
}

.popup {
    margin: var(--size-padding-big);
    --button-border-colour: var(--colour-menu-button-border);
    --button-hover-colour: var(--colour-settings-background);
    --button-active-colour: var(--colour-tabs-background);
}

.popup button {
    font: var(--text-large);
    margin-left: var(--size-padding-big);
    padding: var(--size-padding-big);
}