.button {
  font: var(--text-large);
  margin: 0 var(--size-padding-small);
  padding: var(--size-padding-small) var(--size-padding-big);
}

.smallButton {
  font: var(--text-small);
}

.icon {
  font: var(--text-medium);
  margin: 0.1rem var(--size-padding-small) 0 var(--size-padding-small);
}