.heading {
  font: var(--text-large);
  color: var(--colour-text-secondary);
}

.searchPanel {
  border: var(--border-width-small) solid var(--colour-page-border);
  margin: 0px 0px var(--size-padding-massive) var(--size-padding-massive);
  padding: var(--size-padding-small);
  border-radius: var(--border-radius-big);
  background-color: var(--colour-floating-panel);
}

.search {
  font: var(--text-large);
  color: var(--colour-text-secondary);
  border: var(--border-width-small) solid var(--colour-page-border);
  margin: var(--size-padding-small);
  border-radius: var(--border-radius-small);
}

.filters {
  margin: var(--size-padding-small);
}

.iconButton {
  --button-hover-colour: var(--colour-tabs-background);
  color: var(--colour-iconbutton);
}

.list {
  overflow: scroll;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  position: relative;
  padding: 0;
  margin: var(--size-padding-small);
  line-height: 1.2em;
}

.result {
  display: flex;
  flex-direction: row;
  align-items: center;
  --button-hover-colour: var(--colour-settings-hover);
  --button-active-colour: var(--colour-page-border);
  font: var(--text-medium);
}

.resultDelete {
  margin-right: var(--size-padding-small);
  color: var(--colour-iconbutton);
}

.searchIcon {
  font: var(--text-medium);
  color: var(--colour-page-border);
  padding: 0px 6px;
}

.ul {
  position: absolute;
  padding: 0;
  margin: 0;
  text-decoration: none;
  list-style: none;
  width: 100%;
}

.li {
  white-space: nowrap;
}

.zero {
  color: #414b51;
}

.label {
  padding-right: 6px;
  color: #c3d7ef;
}

.type {
  color: #414b51;
  text-transform: uppercase;
}

.button {}

.resultLabel {
  overflow: hidden;
  text-overflow: ellipsis;
}

.address {
  font-family: 'Source Code Pro';
  padding-right: 8px;
  color: #788b94;
  display: inline-block;
  margin: 2px 3px 1px 1px;
  font-size: 0.8em;
  border-radius: 0.35em;
  box-sizing: border-box;
  padding: 0.15em 0.3em;
  box-shadow: inset 0 0 0 1px #78c3a8;
}

.address:hover {
  box-shadow: inset 0 0 0 1px #388166;
}

.count {
  font-family: 'Source Code Pro';
  padding-right: 8px;
  color: #788b94;
  display: inline-block;
  margin: 2px 3px 1px 1px;
  font-size: 0.8em;
  border-radius: 0.35em;
  box-sizing: border-box;
  padding: 0.15em 0.3em;
  box-shadow: inset 0 0 0 1px #374540;
}