.divider {
    color: var(--colour-page-border);
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
    cursor: col-resize;
    padding: 0 var(--size-padding-small);
    height: 100%;
    display: flex;
    align-items: center;
  }