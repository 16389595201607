.hidden {
    display: none;
}

.p {
    padding: 0;
    margin: 0;
}

.ul {
    margin: var(--border-width-small);
    padding: 0;
    border: var(--border-width-small) solid var(--colour-page-border);
    border-radius: var(--border-radius-small);
    overflow: hidden;
    list-style: none;
}

.li {
    display: inline-block;
}

.enum {
    display:inline-block;
    padding: var(--border-width-small) var(--size-padding-small);
}

.selected {
    background-color: var(--colour-enum-selected);
}

.unselected {
    border-radius: 0;
}

.disabled {
    color: var(--colour-disabled);
}

.modalTextEditorField {
    margin: var(--size-padding-small);
    font: var(--text-settings);
}

.iconButton {
    color: var(--colour-iconbutton);
}