.typeUnknown {
    background-color: transparent;
    border-color: transparent;
}

.typeData {
    background-color: rgba(var(--colour-data-source), 0.1);
    border-color: rgba(var(--colour-data-source), 0.8);
}

.typeGraphics {
    background-color: rgba(var(--colour-graphics-source), 0.1);
    border-color: rgba(var(--colour-graphics-source), 0.8);
}

.typeCode {
    background-color: rgba(var(--colour-code-source), 0.1);
    border-color: rgba(var(--colour-code-source), 0.8);
}

.line {
    display: inline-flex;
    align-items: center;

    padding-left: var(--size-padding-small);
    padding-right: var(--size-padding-small);

    border-style: solid;
    border-top-width: 0px;
    border-bottom-width: 0px;
    border-right-width: var(--size-code-thin-border);
}

.extraGapSpace {
    height: 100%;
    align-items: start;
}

.byteLine {
    font: var(--text-code);

    border-left-width: var(--size-code-thin-border);
    margin-left: var(--size-code-gutter-with-thin-border);
    width: var(--size-code-width-with-thin-border);
}

.commentLine {
    font: var(--text-code-comment);

    border-left-width: var(--size-code-thick-border);
    margin-left: var(--size-code-gutter-with-thick-border);
    width: var(--size-code-width-with-thick-border);

    padding-left: var(--size-code-text-inset);

    height: 21px;
}

.graphicsPreviewLine {
    border-left-width: var(--size-code-thick-border);
    margin-left: var(--size-code-gutter-with-thick-border);
    width: var(--size-code-width-with-thick-border);

    padding-left: var(--size-code-text-inset);
    padding-top: var(--size-padding-small);
    padding-bottom: var(--size-padding-small);
}

.smallLabelLine {
    font: var(--text-code-small-label);

    border-left-width: var(--size-code-thick-border);
    margin-left: var(--size-code-gutter-with-thick-border);
    width: var(--size-code-width-with-thick-border);

    padding-left: var(--size-code-text-inset);
    padding-top: var(--size-code-small-label-gap);
}

.bigLabelLine {
    font: var(--text-code-large-label);

    border-left-width: var(--size-code-thick-border);
    margin-left: var(--size-code-gutter-with-thick-border);
    width: var(--size-code-width-with-thick-border);

    margin-top: 16px;
    height: calc(100% - 16px) !important;

    padding-left: var(--size-code-text-inset);
    border-top-width: var(--size-code-thin-border);
    border-top-left-radius: var(--border-radius-big);
    border-top-right-radius: var(--border-radius-big);
}

.startStyle {
    border-top-width: var(--size-code-thin-border);
    border-top-left-radius: var(--border-radius-big);
    border-top-right-radius: var(--border-radius-big);
    height: 100%;
    align-items: end !important;
}

.endStyle {
    border-bottom-width: var(--size-code-thin-border);
    border-bottom-left-radius: var(--border-radius-big);
    border-bottom-right-radius: var(--border-radius-big);
    height: 100%;
    align-items: start !important;
}

.startEndStyle {
    align-items: center !important;
}

/* --------------- */

.commentText {
    opacity: 1.0;
}

.range {
    border-style: solid;
    border-radius: 6px;
}

.segment {
    padding-right: 12px;
}

.byte {
    padding-left: 3px;
    padding-right: 3px;
    outline: solid 1px #00000000;
    border-radius: 0.35em;
    outline-offset: -1px;
}

.labelLength {
    color: #c0c0c0;
    font-size: 0.8em;
    padding-left: 2px;
}

.labelTitle {
    color: #606060;
    padding-left: 12px;
}

.isUndefined {
    color: #00000040;
}

.invalid {
    color: #00000040;
}

.selected {
    color: #303030;
    background-color: #ff000040;
}

.selectedinvalid {
    color: #30303080;
    background-color: #ff000040;
}

.accessed {
    border: solid 1px #0000ff;
}

.labelOuter {
    border-radius: 0.35em;
    color: #00000070;
    display: inline-flex;
    align-items: stretch;
    box-sizing: border-box;
    padding: 0 0.15em;

    --colour-valid-ram-border: #78c3a8;
    --colour-valid-ram-border-hover: #388166;
    --colour-valid-ram: #add6c7;
    --colour-valid-ram-hover: #7ebaa4;

    --colour-valid-rom-border: #c2c2c2;
    --colour-valid-rom-border-hover: #7b7b7b;
    --colour-valid-rom: #d8d8d8;
    --colour-valid-rom-hover: #b7b7b7;

    --colour-invalid-border: #a56e64;
    --colour-invalid-border-hover: #6c3628;
    --colour-invalid: #d6b3ad;
    --colour-invalid-hover: #bb8379;
}

.labelOuter.labelValid {
    box-shadow: inset 0 0 0 1px var(--colour-valid-ram-border);
}

.labelOuter:hover.labelValid {
    box-shadow: inset 0 0 0 1px var(--colour-valid-ram-border-hover);
}

.labelOuter.rom.labelValid {
    box-shadow: inset 0 0 0 1px var(--colour-valid-rom-border);
}

.labelOuter:hover.rom.labelValid {
    box-shadow: inset 0 0 0 1px var(--colour-valid-rom-border-hover);
}

.labelOuter.labelInvalid {
    box-shadow: inset 0 0 0 1px var(--colour-invalid-border);
}

.labelOuter:hover.labelInvalid {
    box-shadow: inset 0 0 0 1px var(--colour-invalid-border-hover);
}

.label {
    border-top-right-radius: 0.35em;
    border-bottom-right-radius: 0.35em;
    margin-left: 0.15em;
    margin-right: -0.15em;
    padding: 0.175em 0.4em 0em 0.4em;
    font-family: "Source Sans Pro";
    font-size: 0.8em;
    color: #000000ff;
}

.label.labelValid {
    background-color: var(--colour-valid-ram);
}

.labelOuter:hover .labelValid {
    background-color: var(--colour-valid-ram-hover);
}

.label.rom.labelValid {
    background-color: var(--colour-valid-rom);
}

.labelOuter:hover .rom.labelValid {
    background-color: var(--colour-valid-rom-hover);
}

.label.labelInvalid {
    background-color: var(--colour-invalid);
}

.labelOuter:hover .labelInvalid {
    background-color: var(--colour-invalid-hover);
}