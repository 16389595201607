.container {
    width: 100%;
    height: 100%;
    overflow: auto;
    user-select: text;
    padding: var(--size-padding-huge);
}

.column {
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.container h1 {
    font: var(--text-huge);
}

.container h2 {
    font: var(--text-c64);
    font-size: 2rem;
    white-space-collapse: preserve;
    text-align: center;
}

.container h3 {
    font: var(--text-large);
    border-left: var(--size-padding-small) solid var(--colour-disabled);
    padding: var(--size-padding-big);
}

.container p {
    font: var(--text-medium);
}

.emphasis {
    font-weight: bold;
}

.container img {
    width: 100%;
}

.labelWithIcon {
    background: var(--colour-background);
    border-radius: var(--border-radius-small);
    padding: 0 var(--size-padding-small);
    white-space: nowrap;
    user-select: none;
}

.icon {
    font-size: 0.8em;
    margin: 0 var(--size-padding-small) 0 0;
}

.public {
    color: var(--colour-0E);
    font: var(--text-medium-heavy);
}

.publicStatus {
    color: var(--colour-0E);
    font: var(--text-medium-heavy);
    background-color: transparent;
    border: var(--border-width-small) solid var(--colour-page-border);
}

.projectIconFix svg {
    font-size: 0.6em;
    margin-left: var(--size-padding-small);
    margin-right: 0;
}

.copyIconFix {
    font-size: 0.8em;
    color: var(--colour-iconbutton);
}

.singleIconFix svg {
    margin: 0 var(--size-padding-small);
}

.tabHeading {
    font: var(--text-medium-heavy);
}

.filterIcon {
    background: var(--colour-background);
    border-radius: var(--border-radius-small);
    padding: 0 var(--size-padding-small);
    white-space: nowrap;
    user-select: none;

    display: inline-flex;
    align-items: center;
    user-select: none;
}

.filterIcon>span {
    background-color: rgb(6, 214, 160);
    padding: 0 var(--size-padding-filter-icon);
    margin-right: var(--size-padding-tiny);
    border-radius: var(--border-radius-small);
}