.card {
    padding: var(--size-padding-small);
    white-space: nowrap;
}

.name {
    font: var(--text-medium);
    position: relative;
}

.author {
    color: var(--colour-text-dark);
    font: var(--text-small-heavy);
}

.icon {
    margin-right: var(--size-padding-small);
}

.publicIcon {
    position: absolute;
    left: calc(var(--size-padding-huge) * -1);
    font-size: 0.6em;
    margin-left: var(--size-padding-small);
    color: var(--colour-0E);
    padding: var(--size-padding-tiny);
    background: white;
    border-radius: var(--border-radius-big);
}

.details {
    font: var(--text-small);
    color: var(--colour-text-secondary);
}

.operations {
    --button-hover-colour: var(--colour-settings-background);
    --button-active-colour: var(--colour-tabs-background);
}

.operations>button {
    margin-left: var(--size-padding-small);
    color: var(--colour-iconbutton);
    padding: var(--size-padding-big);
}

.operations>button:first-of-type {
    margin-left: 0;
}