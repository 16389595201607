.horizontal>* {
    padding-right: 8px;
    padding-left: 8px;
    border-right: 1px solid var(--colour-text-secondary);
}

.horizontal>:first-child {
    padding-left: 0px;
}

.horizontal>:last-child {
    border-right: 1px solid transparent;
    padding-right: 0px;
}