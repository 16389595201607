.panel {
  background-color: #ffffffe0;
  max-height: 100%;
  max-width: 100%;
  position: relative;
  overflow: hidden;
}


.inner {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.frame {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.ramRomSwitch {
  font: var(--text-large);
  text-align: right;
  --button-hover-colour: var(--colour-settings-hover);
  --button-active-colour: var(--colour-page-border);
  padding-top: var(--size-padding-small);
  padding-bottom: var(--size-padding-small);
}

.ramRomSwitch ul {
  display: inline-flex;
  background-color: var(--colour-settings-background);
}