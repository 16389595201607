.panel {
    text-align: center;
    background-color: #333f50;
    font-size: 0.85em;
}

.logo {
    font: var(--text-huge);
    font-weight: bold;
    text-align: center;
    margin: 0 20px 0 10px;
}

.subheading {
    margin: -8px 20px 4px 10px;
    font: var(--text-small);
}