.panel {
    position: relative;
    margin-right: var(--size-padding-big);
    --button-hover-colour: var(--colour-menu-button-hover);
    --button-active-colour: var(--colour-menu-button-active);
}

@media screen and (width <600px) {
    .name {
        display: none !important;
    }
}

@media screen and (width <900px) {
    .detail {
        display: none !important;
    }
}

.detail {
    opacity: 60%;
}

.button {
    font: var(--text-large);
    margin-left: var(--size-padding-big);
    padding: var(--size-padding-big);
}


.popup {
    margin: var(--size-padding-big);
    --button-border-colour: var(--colour-menu-button-border);
    --button-hover-colour: var(--colour-settings-background);
    --button-active-colour: var(--colour-tabs-background);
    --colour-google: #dd4b39;
    --colour-github: #4b5563;
}

.signInButton {
    padding: var(--size-padding-huge);
    margin: var(--size-padding-small);
    display: inline-block;
}

.signInButton:hover {
    opacity: 60%;
}

.google {
    background-color: var(--colour-google);
    --button-hover-colour: var(--colour-google);
    color: white;
}

.github {
    background-color: var(--colour-github);
    --button-hover-colour: var(--colour-github);
    color: white;
}

.x {
    background-color: var(--colour-github);
    --button-hover-colour: var(--colour-github);
    color: white;
}