.container {
    display: inline-flex;
    align-items: center;
    margin-right: var(--size-padding-massive);
}

.container button {
    font: var(--text-small);
    margin: 0 var(--size-padding-small) 0 0;
    padding: var(--size-padding-small) var(--size-padding-big);
    --colour-highlight: var(--colour-settings-tab);
    --colour-from-nothing: color-mix(in srgb, transparent, var(--colour-highlight) 25%);
    --colour-from-highlight: color-mix(in srgb, transparent, var(--colour-highlight) 75%);
}

.private {
    --button-active-colour: var(--colour-highlight);
    --button-hover-colour: var(--colour-from-nothing);
}

.public {
    background-color: var(--colour-highlight);
    --button-hover-colour: var(--colour-from-highlight);
    --button-active-colour: transparent;
    color: var(--colour-0E);
    font: var(--text-small-heavy) !important;
}
.public .icon {
    color: var(--colour-0E);
}

.readOnly {
    font: var(--text-small);
    border: var(--border-width-small) solid var(--colour-page-border);
    border-radius: var(--border-radius-small);
    padding: var(--size-padding-small);
}

.icon {
    font: var(--text-small);
    margin: 0 var(--size-padding-small) 0 0;
    color: var(--colour-iconbutton);
}

.input {
    font: var(--text-large);
    margin: var(--size-padding-small);
    padding: 0 var(--size-padding-small) var(--size-padding-small) var(--size-padding-small);
    background: var(--colour-settings-background);
    --input-border-colour: var(--colour-page-border);
}

.input:focus {
    border-color: var(--colour-focused-input);
}