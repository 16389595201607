.ribbon {}

.ribbonMenu {}

.measurable {
    position: fixed;
    visibility: hidden;
}

.button {
    font: var(--text-large);
}