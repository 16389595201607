.numberInput {
    --input-border-colour: var(--colour-page-border);
    font: var(--text-settings-number-input);
}

.textInput {
    --input-border-colour: var(--colour-page-border);
    font: var(--text-settings-text-input);
}

.textArea {
    resize: none;
}

.long {
}

.bad {
    border-color: var(--colour-input-bad);
}