.tabGroup {
    background-color: var(--colour-tabs-background);
    --button-hover-colour: var(--colour-tabs-hover);
    --button-active-colour: var(--colour-settings-background);
}

.tab {
    font: var(--text-large);
    margin: var(--size-padding-small);
    padding: var(--size-padding-small) var(--size-padding-big);
    border-radius: var(--border-radius-small);
    border: var(--border-width-small) solid transparent;
    display: inline-flex;
    align-items: center;
}

.unselected {
    --link-colour: transparent;
    --link-colour-hover: var(--button-hover-colour);
    --link-colour-active: var(--button-active-colour);
}

.unselected:link,
.unselected:visited {
    text-decoration: none;
    padding: var(--size-padding-small) var(--size-padding-big);
}

.selected {
    background-color: var(--colour-settings-background);
}

.icon {
    font: var(--text-medium);
    margin: 0.1rem var(--size-padding-small) 0 var(--size-padding-small);
}

.Public {
    font: var(--text-large-heavy);
    color: var(--colour-0E);
}