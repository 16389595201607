.projectPage {
    --colour-markup-icon-opacity: 0.4;
    --colour-markup-entry-point: rgb(var(--colour-code-source), var(--colour-markup-icon-opacity));
    --colour-markup-override: rgb(var(--colour-code-source), var(--colour-markup-icon-opacity));
    --colour-markup-label: rgb(var(--colour-code-source), var(--colour-markup-icon-opacity));
    --colour-markup-comment: rgb(var(--colour-code-source), var(--colour-markup-icon-opacity));
    --colour-markup-data: rgb(var(--colour-data-source), var(--colour-markup-icon-opacity));
    --colour-markup-pointer: rgb(var(--colour-data-source), var(--colour-markup-icon-opacity));
    --colour-markup-formatting: rgb(var(--colour-data-source), var(--colour-markup-icon-opacity));
    --colour-markup-graphic: rgb(var(--colour-graphics-source), var(--colour-markup-icon-opacity));
    --colour-markup-unknown: rgb(var(--colour-unknown-source), var(--colour-markup-icon-opacity));
    --colour-markup-icon-text: #000000c0;
}

.tabsPanel {
    background-color: var(--colour-tabs-background);
    --button-hover-colour: var(--colour-tabs-hover);
    --button-active-colour: var(--colour-settings-background);
}