.beingPicked {
    border-color: var(--colour-page-border);
}

.swatches {
    position: relative;
}

.swatch {
    margin-right: var(--size-padding-small);
    width: var(--size-colour-swatches-small);
    height: var(--size-colour-swatches-small);
    border: var(--border-width-small) solid var(--colour-page-border);
    border-radius: var(--border-radius-small);
    display: inline-block;
}

.bigSwatch {
    margin: calc(0.5 * var(--size-padding-small));
    width: var(--size-colour-swatches-big);
    height: var(--size-colour-swatches-big);
    border: var(--border-width-small) solid var(--colour-page-border);
    border-radius: var(--border-radius-big);
    display: inline-block;
    padding: 0;
}

.bigSwatch:hover {
    border-color: var(--colour-focused-input);
}

.picker {
    padding: var(--size-padding-small);
    background-color: var(--colour-settings-background);
}

.col00 { background-color: var(--colour-00); --button-hover-colour: var(--colour-00); --button-active-colour: var(--colour-00); }
.col01 { background-color: var(--colour-01); --button-hover-colour: var(--colour-01); --button-active-colour: var(--colour-01); }
.col02 { background-color: var(--colour-02); --button-hover-colour: var(--colour-02); --button-active-colour: var(--colour-02); }
.col03 { background-color: var(--colour-03); --button-hover-colour: var(--colour-03); --button-active-colour: var(--colour-03); }
.col04 { background-color: var(--colour-04); --button-hover-colour: var(--colour-04); --button-active-colour: var(--colour-04); }
.col05 { background-color: var(--colour-05); --button-hover-colour: var(--colour-05); --button-active-colour: var(--colour-05); }
.col06 { background-color: var(--colour-06); --button-hover-colour: var(--colour-06); --button-active-colour: var(--colour-06); }
.col07 { background-color: var(--colour-07); --button-hover-colour: var(--colour-07); --button-active-colour: var(--colour-07); }
.col08 { background-color: var(--colour-08); --button-hover-colour: var(--colour-08); --button-active-colour: var(--colour-08); }
.col09 { background-color: var(--colour-09); --button-hover-colour: var(--colour-09); --button-active-colour: var(--colour-09); }
.col0A { background-color: var(--colour-0A); --button-hover-colour: var(--colour-0A); --button-active-colour: var(--colour-0A); }
.col0B { background-color: var(--colour-0B); --button-hover-colour: var(--colour-0B); --button-active-colour: var(--colour-0B); }
.col0C { background-color: var(--colour-0C); --button-hover-colour: var(--colour-0C); --button-active-colour: var(--colour-0C); }
.col0D { background-color: var(--colour-0D); --button-hover-colour: var(--colour-0D); --button-active-colour: var(--colour-0D); }
.col0E { background-color: var(--colour-0E); --button-hover-colour: var(--colour-0E); --button-active-colour: var(--colour-0E); }
.col0F { background-color: var(--colour-0F); --button-hover-colour: var(--colour-0F); --button-active-colour: var(--colour-0F); }