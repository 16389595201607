/* SequencesAs... */
.unselected {
    opacity: 0.3;
}

.selected {
    box-shadow: inset 0 0 0 2px color-mix(in srgb, var(--colour), #000000 30%);
}

.container {
    user-select: none;
    --colour: #d8d8d8;
}

.container button {
    padding: 0 1px;
}

.container>button>span {
    display: inline-block;
    border-radius: 3px;
    background-color: rgb(from var(--colour) r g b / 65%);
    margin: 1px 0;
}

.container>button>span:hover {
    opacity: 0.6;
}

.container span:active {
    opacity: 1.0;
}

.detail {
    padding: 0 3px;
}

.byte {
    padding: 0 3px;
    font: var(--text-code);
}


/* Byte */
.describedBytesAsBitsContainer {
    display: flex;
}

.describedBytesLabelsContainer {
    margin-top: 3px;
}

.describedByteContainer {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    white-space: nowrap;
    margin: 0 1px;
}

.groupedBits {
    display: inline-block;
    background-color: rgb(from var(--colour) r g b / 65%);
    padding: 0 3px;
}

.groupedBits:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
}

.groupedBits:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
}

.bitgroupLabel {
    border-radius: 3px;
    background-color: rgb(from var(--colour) r g b / 65%);
    padding: 0 3px;
}

.bitgroupUnlabelled {
    --colour: #f0f0f0;
}

.bitgroup0 {
    --colour: #afdf8a;
}

.bitgroup1 {
    --colour: #8fba6e;
}

.bitgroup2 {
    --colour: #e2ef6d;
}

.bitgroup3 {
    --colour: #c0d21d;
}

.bitgroup4 {
    --colour: #e2b684;
}

.bitgroup5 {
    --colour: #cd7232;
}

.bitgroup6 {
    --colour: #d8d8d8;
}

.bitgroup7 {
    --colour: #c0c0c0;
}