.refs {
    color: #209040;
    padding-left: 12px;
    font-size: 0.8em;
}

.ref {
    display: inline-block;
    padding: 0px 3px;
    cursor: pointer;
    border-radius: 0.35em;
    border: 1px solid #78c3a8;
}

.rom {
    color: #808080;
    border-color: #b0b0b0;
}

.ref:hover {
    border-color: #388166;
    color: #00000070;
}

.refGroup {
    padding-right: 6px;
}

.popupHolder {
    display:inline-block;
    position: relative;
    height:1em;
    width:0;
}

.refsPopup {
    align-items: center;
    display: flex;
    position: absolute;
    transform: translateY(-50%);
    left:0;
    top:0.5em;
    z-index: 100;
}

.refsPopup ul {
    padding:3px;
    background-color: #f0f8f0;
    border-radius: 0.35em;
    border: 1px solid #78c3a8;
}

.refsPopup li {
    list-style: none;
}

.text {
    font-family: "Source Sans Pro";
}