.heading {
    margin-top: var(--size-padding-massive);
    display: flex;
    flex-direction: row;
    align-items: center;
}

.heading .name {
    font: var(--text-huge);
}

.heading .divider {
    font: var(--text-huge);
    color: var(--colour-disabled);
    margin-left: var(--size-padding-big);
    margin-right: var(--size-padding-big);
}

.heading .address {
    font: var(--text-code);
}

.heading .length {
    font: var(--text-code);
    color: var(--colour-disabled);
}

.subHeading {
    padding-left: var(--size-padding-small);
    font: var(--text-large);
}

.set {
    margin-top: var(--size-padding-huge);
    margin-left: calc(var(--size-padding-ginormous) - var(--size-padding-small) - var(--size-padding-small));
}

.subSet {
    margin-top: var(--size-padding-huge);
    margin-left: var(--size-padding-ginormous);
    border-left: var(--size-padding-small) solid var(--colour-disabled);
}
