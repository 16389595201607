.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.previewPanel {
    --margin: var(--size-padding-massive);
    --padding: var(--size-padding-small);
    --border: var(--border-width-small);


    --full-width-for-graphic: calc(var(--parent-width) - var(--margin) - var(--border) - var(--padding) - var(--padding) - var(--border));
    --graphic-height-at-full-width: calc(var(--full-width-for-graphic) / var(--aspect));
    --panel-height-at-full-width: calc(var(--padding) + var(--graphic-height-at-full-width) + var(--controls-height) + var(--heading-height) + var(--padding));
    --max-panel-height: calc(var(--parent-height) - var(--margin) - var(--border) - var(--border) - var(--margin));

    --actual-panel-height: min(var(--panel-height-at-full-width), var(--max-panel-height));
    --actual-graphic-height: calc(var(--actual-panel-height) - var(--padding) - var(--controls-height) - var(--heading-height) - var(--padding));
    --actual-graphic-width: calc(var(--actual-graphic-height) * var(--aspect));

    border: var(--border) solid var(--colour-page-border);
    margin: var(--margin) 0px var(--margin) var(--margin);
    padding: var(--padding);
    border-radius: var(--border-radius-big);
    background-color: var(--colour-floating-panel);
    width: calc(var(--parent-width) - var(--margin));
    height: min(100%, calc(var(--actual-panel-height) + var(--border) + var(--border)));

    display: flex;
    flex-direction: column;
}

.graphicWrapper {
    width: var(--actual-graphic-width);
    height: 100%;
    margin: auto;
}

.heading {
    font: var(--text-large);
    color: var(--colour-text-secondary);
}

.controls {
    text-align: right;
    padding-top: var(--size-padding-small);
}

.button {
    font: var(--text-large);
    padding: var(--size-padding-big);
    --button-hover-colour: var(--colour-settings-background);
    --button-active-colour: var(--colour-tabs-background);
}

.icon {
    font-size: 0.9em;
    color: var(--colour-markup-icon-text);
    padding: var(--size-padding-tiny) var(--size-padding-tinyish);
    margin-right: var(--size-padding-small);
}