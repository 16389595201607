.row {
    white-space: nowrap;
    display: flex;
    align-items: center;
}

.label {
    padding-top: var(--size-padding-small);
    padding-bottom: var(--size-padding-small);
}

.child {
    display: inline-flex;
}