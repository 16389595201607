.icon {
    font-size: 0.9em;
    color: var(--colour-markup-icon-text);
    padding: var(--size-padding-tiny) var(--size-padding-tinyish);
    border: var(--border-width-small) solid var(--colour-iconbutton);
    border-radius: var(--border-radius-small);
    margin-right: var(--size-padding-small);
}

.graphic {
    background-color: var(--colour-markup-graphic);
}

.entrypoint {
    background-color: var(--colour-markup-entry-point);
}

.data {
    background-color: var(--colour-markup-data);
}

.formatting {
    background-color: var(--colour-markup-formatting);
}

.pointer {
    background-color: var(--colour-markup-pointer);
}

.label {
    background-color: var(--colour-markup-label);
}

.comment {
    background-color: var(--colour-markup-comment);
}

.override {
    background-color: var(--colour-markup-override);
}

.unknown {
    background-color: var(--colour-markup-unknown);
}