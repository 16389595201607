@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400');
@import url('https://fonts.googleapis.com/css?family=Source+Code+Pro:400');

@font-face {
  font-family: 'C64ProMono';
  src: url('C64_Pro_Mono-STYLE.woff2') format('woff2');
}

body {
  --code-medium: 'Source Code Pro', sans-serif;
  --text-huge: normal 300 2rem 'Source Sans Pro', sans-serif;
  --text-large: normal 300 1.25rem 'Source Sans Pro', sans-serif;
  --text-large-heavy: normal 400 1.25rem 'Source Sans Pro', sans-serif;
  --text-medium: normal 300 1.0rem 'Source Sans Pro', sans-serif;
  --text-medium-heavy: normal 400 1.0rem 'Source Sans Pro', sans-serif;
  --text-small: normal 300 0.8rem 'Source Sans Pro', sans-serif;
  --text-small-heavy: normal 400 0.8rem 'Source Sans Pro', sans-serif;
  --text-tiny: normal 300 0.65rem 'Source Sans Pro', sans-serif;
  --text-settings: normal 300 0.9rem 'Source Sans Pro', sans-serif;
  --text-settings-number-input: normal 300 0.9rem 'Source Code Pro', sans-serif;
  --text-code-large-label: normal 300 1.5rem 'Source Sans Pro', sans-serif;
  --text-code-small-label: normal 400 1.0rem 'Source Sans Pro', sans-serif;
  --text-code-comment: italic 300 1.0rem 'Source Sans Pro', sans-serif;
  --text-code: normal 300 1.0rem 'Source Code Pro', sans-serif;
  --text-c64: normal 300 1.0rem 'C64ProMono', sans-serif;

  --colour-background: #efefef;
  --colour-tabs-background: #d6d6d6;
  --colour-settings-background: #e9e9e9;
  --colour-page-background: #ffffff;
  --colour-floating-panel: #fafafa;
  --colour-focused-input: #808080;
  --colour-input-bad: #ff000080;

  --colour-text-dark: #000000;
  --colour-text-secondary: #808080;
  --colour-disabled: #00000040;
  --colour-iconbutton: #00000080;

  --colour-modal-overlay: #ffffff00;
  --colour-modal-shadow: #00000040;
  --colour-shadow-near: #000000c0;
  --colour-shadow-medium: #00000080;
  --colour-shadow-far: #00000040;
  --colour-fetching-overlay: #ffffff80;
  --colour-page-border: #c8c8c8;
  --colour-tabs-hover: color-mix(in srgb, var(--colour-tabs-background), var(--colour-settings-background));
  --colour-menu-button-border: var(--colour-page-border);
  --colour-menu-button-hover: color-mix(in srgb, var(--colour-background), var(--colour-page-background));
  --colour-menu-button-active: color-mix(in srgb, var(--colour-menu-button-hover), var(--colour-page-background));
  --colour-selected: #ff000080;

  --colour-settings-tab: #f8f8f8;
  --colour-settings-hover: color-mix(in srgb, var(--colour-settings-background), var(--colour-page-border));
  --colour-enum-selected: var(--colour-page-border);

  --colour-00: rgb(0, 0, 0);
  --colour-01: rgb(255, 255, 255);
  --colour-02: rgb(136, 0, 0);
  --colour-03: rgb(170, 255, 238);
  --colour-04: rgb(204, 68, 204);
  --colour-05: rgb(0, 204, 85);
  --colour-06: rgb(0, 0, 170);
  --colour-07: rgb(238, 238, 119);
  --colour-08: rgb(221, 136, 85);
  --colour-09: rgb(102, 68, 0);
  --colour-0A: rgb(255, 119, 119);
  --colour-0B: rgb(51, 51, 51);
  --colour-0C: rgb(119, 119, 119);
  --colour-0D: rgb(170, 255, 102);
  --colour-0E: rgb(0, 136, 255);
  --colour-0F: rgb(187, 187, 187);

  --border-width-small: 1px;
  --border-width-medium: 2px;
  --border-radius-big: 4.5px;
  --border-radius-small: 3px;

  --size-padding-tiny: 1px;
  --size-padding-tinyish: 2px;
  --size-padding-small: 3px;
  --size-padding-big: 6px;
  --size-padding-huge: 9px;
  --size-padding-massive: 12px;
  --size-padding-ginormous: 24px;
  --size-padding-filter-icon: 5px;
  --size-code-small-label-gap: 10px;
  --size-code-big-label-gap: 19px;
  --size-code-text-inset: 56px;
  --size-code-thick-border: 4px;
  --size-code-thin-border: 1px;
  --size-code-gutter: 4px;
  --size-code-gutter-with-thick-border: calc(var(--size-code-gutter) - var(--size-code-thick-border));
  --size-code-gutter-with-thin-border: calc(var(--size-code-gutter) - var(--size-code-thin-border));
  --size-code-width: 750px;
  --size-code-width-with-thick-border: calc(var(--size-code-width) + var(--size-code-thick-border) + var(--size-code-thin-border));
  --size-code-width-with-thin-border: calc(var(--size-code-width) + var(--size-code-thin-border) + var(--size-code-thin-border));

  --size-colour-swatches-small: 0.75rem;
  --size-colour-swatches-big: calc(2 * var(--size-colour-swatches-small));

  --link-text-colour: var(--colour-text-dark);
  --link-colour: #efefef;
  --link-colour-hover: color-mix(in srgb, var(--link-colour), var(--link-colour-active));
  --link-colour-active: #d0d0d0;

  margin: 0;
  padding: 0;
  font: var(--text-medium);
  user-select: none;
  min-height: 100vh;
  min-width: 100vw;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior: none;

  background-color: var(--colour-background);
  color: var(--colour-text-dark);
}

#root {
  min-height: 100vh;
  min-width: 100vw;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

* input,
* textarea {
  all: unset;
  appearance: auto;
  border-radius: var(--border-radius-small);
  border: var(--border-width-small) solid var(--input-border-colour);
  display: inline-flex;
  text-overflow: ellipsis;
  vertical-align: top;
}

* input:focus,
* textarea:focus {
  border-color: var(--colour-focused-input);
}

* input:disabled,
* textarea:disabled {
  cursor: unset;
  opacity: 0.5;
}


* button {
  all: unset;
  appearance: auto;
  cursor: pointer;
  border-radius: var(--border-radius-small);
  padding-left: var(--size-padding-small);
  padding-right: var(--size-padding-small);
  border: var(--border-width-small) solid var(--button-border-colour);
  display: inline-flex;
  align-items: center;
}

* button:focus {
  outline: none;
}

* button:disabled {
  cursor: unset;
  opacity: 0.5;
}

* button:hover {
  background-color: var(--button-hover-colour);
}

* button:hover:disabled {
  background-color: transparent !important;
}

* button:active {
  background-color: var(--button-active-colour);
}

* a:link,
* a:visited {
  color: var(--link-text-colour);
  text-decoration-style: solid;
  border-radius: var(--border-radius-small);
  background-color: var(--link-colour);
  padding: 0px var(--size-padding-small);
}

* a:hover {
  background-color: var(--link-colour-hover);
}

* a:active {
  background-color: var(--link-colour-active);
}